import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import axios from "axios";
import store from "@/stores/appStore";

// Initialize Firebase
const firebaseConfig = {
    apiKey: process.env.VUE_APP_apiKey,
    authDomain: process.env.VUE_APP_authDomain,
    projectId: process.env.VUE_APP_projectId,
    storageBucket: process.env.VUE_APP_storageBucket,
    messagingSenderId: process.env.VUE_APP_messagingSenderId,
    appId: process.env.VUE_APP_appId
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Request permission and retrieve FCM token
Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
        console.log('Notification permission granted.');
        return getToken(messaging, { vapidKey: process.env.VUE_APP_vapid_Key });
    } else {
        console.log('Unable to get permission to notify.');
        throw new Error('Notification permission denied');
    }
})
    .then((token) => {
        if (token) {
            console.log('Token received:', token);
            let userToken = store.getters.token;
            if (userToken) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + userToken;
            }
            // Post the FCM token to your server
            return axios.post(
                store.getters.url + "/api/api-web-notification-token",
                { token: token },
                {
                    headers: {
                        "Content-type": "application/json",
                        Accept: "application/json"
                    }
                }
            );
        }
    })
    .then(() => {
        console.log("Token successfully sent to server.");
    })
    .catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
    });

// Register the Service Worker for background messaging
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((err) => {
            console.error("Service Worker registration failed: ", err);
        });
}

// Handle foreground messages
onMessage(messaging, (payload) => {
    console.log('Foreground message received:', payload);
    store.dispatch('updateUnreadMessageCounter'); // Example update or notification UI
});

export { messaging };
