<template>
  <div class="hello">
    <q-card class="my-card">
      <q-card-section>
        <q-item-label class="text-h3">
             Compose message <span v-if="this.docket_id !== 0"> about Case : {{this.docket_id.reference}}</span>
        </q-item-label>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-form
            method="post" @submit="this.sendNewMessage()"
            class="q-gutter-md">
          <q-list>
            <q-item>
              <q-select v-model="this.message_type" class="form-control" :options="this.message_types">
              </q-select>
            </q-item>
            <q-item v-if="this.message_type==='Private'">
              <q-item-section>
                <VueNextSelect :clear-on-select="true"
                               :close-on-select="true"
                               :searchable="true"
                               :options="this.options"
                               v-model="this.to_user">
                </VueNextSelect>
              </q-item-section>
            </q-item>
            <q-item v-if="this.message_type==='Group'">
              <q-item-section>
                <VueNextSelect :clear-on-select="true"
                               :close-on-select="true"
                               :searchable="true"
                               :label-by="option => option.name"
                               :options="this.groups"
                               v-model="this.group_id">
                </VueNextSelect>
              </q-item-section>
            </q-item>
            <q-item v-if="this.message_type===this.docket_description">
              <q-item-section>
                <VueNextSelect :clear-on-select="true"
                               :close-on-select="true"
                               :searchable="true"
                               :label-by="option => option.reference"
                               :options="this.dockets"
                               v-model="this.docket_id">
                </VueNextSelect>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-input v-model="this.reply_message" label="Message" :dense="true"></q-input>
              </q-item-section>
              <q-item-section thumbnail style="margin-right: 50px">
                <q-btn type="submit" round color="secondary" icon="navigation"></q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-form>
      </q-card-section>
    </q-card>
    <q-footer bordered class="bg-white text-primary" elevated >
      <q-form
          method="post" @submit="this.sendNewMessage()"
          class="q-gutter-md">
      </q-form>
    </q-footer>
  </div>
</template>

<script>
import {useRoute, useRouter} from 'vue-router'
import axios from "axios";
import {Notify, useQuasar} from 'quasar'
import NoSleep from 'nosleep.js';
import VueNextSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'

export default {
  name: 'PageInbox',
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
  },
  components: {
    VueNextSelect
  },
  data(){
    return {
      options : [],
      draw_list : 0,
      parameter_id : null,
      messages_marked : false,
      message_type : 'Private',
      reply_message : null,
      to_user: null,
      docket_id: 0,
      group_id: 0,
      to_user_id: 0,
      users : [],
      groups: [],
      dockets: [],
    }
  },
  mounted(){
    this.getUsers();
    this.getDockets();
    this.getGroups();
    this.setupParameters();
  },
  computed: {
    url: function(){
      return this.$store.getters.url;
    },
    user_id : function(){
      return JSON.parse( this.$store.getters.user_id );
    },
    level_ones : function(){
      return this.$store.getters.level_ones;
    },
    one_description: function(){
      return this.$store.getters.one_description;
    },
    current_level_one: function(){
      let one = this.$store.getters.current_level_one;
      return one;
    },
    level_twos : function(){
      return this.$store.getters.level_twos;
    },
    two_description: function(){
      return this.$store.getters.two_description;
    },
    current_level_two: function(){
      let two = this.$store.getters.current_level_two;
      return two;
    },
    level_threes : function(){
      return this.$store.getters.level_threes;
    },
    three_description: function(){
      return this.$store.getters.three_description;
    },
    current_level_three: function(){
      let three = this.$store.getters.current_level_three;
      return three;
    },
    level_fours : function(){
      return this.$store.getters.level_fours;
    },
    four_description: function(){
      return this.$store.getters.four_description;
    },
    current_level_four: function(){
      let four = this.$store.getters.current_level_four;
      return four;
    },
    level_fives : function(){
      return this.$store.getters.level_fives;
    },
    current_level_five: function(){
      let five = this.$store.getters.current_level_five;
      return five;
    },
    five_description: function(){
      return this.$store.getters.five_description;
    },
    token: function () {
      return this.$store.getters.token;
    },
    docket_description: function(){
      return this.$store.getters.docket_description;
    },
    message_types: function (){
      return [
          'Private',
          'Group',
          this.$store.getters.docket_description
      ]
    },
  },
  methods: {
    setupParameters() {
      const router = useRouter();
      let token = this.$store.getters.token;
      if( !token ){
        router.push({ name: "login"})
      }
      const route = useRoute()
      this.docket_id = 0;
      console.log(route.params.docket_id);
      if(route.params.docket_id !== undefined){
        this.docket_id = route.params.docket_id;
      }else{
        this.docket_id = 0;
      }
    },
    getGroups(){
      axios.get(this.$store.getters.url+'/api/api-instant-message-groups',{
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token,
        }
      })
          .then((res) => {
            this.groups = res.data;
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    getDockets(){
      const $q = useQuasar();
      axios.get(this.$store.getters.url+'/api/api-dockets',{
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token,
        },
        params:{
          owner_type : 'InvolvingMe',
          not_statuses: 'Closed'
        }
      })
          .then((res) => {
            // eslint-disable-next-line no-unreachable
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: this.docket_description +'s Retrieved !! '
            })
            this.dockets = res.data;
          })
          .catch((error) => {
            console.log(error)
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving tasks: '+error
            })
          }).finally(() => {
        //Perform action in always
      });
    },

    getUsers(){

      axios.get(this.$store.getters.url+'/api/api-users',{
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.token,
        }
      })
          .then((res) => {
            this.users = res.data;
            this.users.forEach((user) => {
              if(user.name!==null){
                this.options.push(user.full_name);
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    goToConversation(to_user_id){
      let url = '/private-message/'+to_user_id;
      window.location.href = url;
    },
    goToDocketConversation(docket_id){
      let url = '/docket-message/'+docket_id;
      window.location.href = url;
    },
    goToGroupConversation(group_id){
      let url = '/group-message/'+group_id;
      window.location.href = url;
    },
    sendNewMessage(){
      let user_id = this.user_id;
      let message = this.reply_message;
      this.users.forEach((user) => {
        if(user.full_name===this.to_user){
          this.to_user_id = user.id;
        }
      });
      let file_data = new FormData();
      file_data.append("text",message);
      file_data.append("sender_id", user_id)
      if(parseInt(this.docket_id) !== 0){
        file_data.append("docket_id", this.docket_id.id);
      }
      if(parseInt(this.group_id) !== 0){
        file_data.append("group_id", this.group_id.id);
      }
      if(parseInt(this.to_user_id) !== 0){
        file_data.append("receiver_id", this.to_user_id);
      }
      let file_config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      // eslint-disable-next-line no-unreachable
      axios.post(this.$store.getters.url+'/api/api-instant-messages', file_data, file_config)
          .then(() => {
            Notify.create({
              progress: true,
              timeout: 1000,
              position: 'center',
              color: 'green-5',
              textColor: 'white',
              icon: 'success',
              message: 'Message sent successfully !! '
            });
            this.reply_message = '';
            if(parseInt(this.docket_id) !== 0){
              this.goToDocketConversation(this.docket_id.id);
            }
            if(parseInt(this.group_id) !== 0){
              this.goToGroupConversation(this.group_id.id);
            }
            if(parseInt(this.to_user_id) !== 0){
              this.goToConversation(this.to_user_id);
            }
          })
          .catch((error) => {
            Notify.create({
              progress: true,
              timeout: 1000,
              position: 'center',
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Failed retrieving messages !! ' + error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>

