<template>
      <q-list bordered>
          <q-item>
            <q-item-section class="text-h5">Task: {{this.returned_tasks.id}}</q-item-section>
          </q-item>
          <q-item>
            <div>
              <div v-bind="getRootProps()">
                <input v-bind="getInputProps()" />
              </div>
              <q-input class="q-mb-md" label="Note" v-model="this.note" type="text"/>
              <label class="q-mx-md q-ml-auto">Take a photo:</label>
              <q-btn icon="camera_alt" round @click="open"></q-btn>
            </div>
          </q-item>
      </q-list>
      <q-list bordered>
        <q-item>
          <q-item-section v-for="(media,index) in media" :key="index">
            <q-img v-if="hasImageExtension(media.img_url)" :src="media.img_url" />
            <q-video v-if="hasVideoExtension(media.img_url)" :src="media.img_url" />
          </q-item-section>
        </q-item>
      </q-list>

</template>


<script>

import axios from "axios";
import {Notify} from "quasar";
import { useDropzone } from "vue3-dropzone";
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
export default {
  name: 'TaskAttachments',
  props: [
      'returned_tasks'
  ],
  emits:[
      'camera_open',
      'camera_close'
  ],
  computed: {
    task_id(){
      return this.returned_tasks.id
    },
    user_id(){
        return this.$store.getters.user_id;
    }
  },
  setup(props, { emit }) {
    let note = ref('');
    function onDrop(acceptFiles) {
      if(note.value === ''){
        Notify.create({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: 'Please add a note'
        })
        return;
      }
      saveFiles(acceptFiles); // saveFiles as callback
    }
    const store = useStore();
    const user_id = computed(() => store.state.user_id);
    function saveFiles(files) {
      files.forEach((panicPhoto) => {
        console.log(panicPhoto);
        let file_data = new FormData();
        file_data.append("action", "uploadFileTask")
        file_data.append("file_note", "task" + '_' + note.value)
        file_data.append("task_id", props.returned_tasks.id);
        file_data.append("desired_file_name", "panic_" + panicPhoto.name + '_' + props.returned_tasks.id)
        file_data.append("user_id", user_id.value)
        file_data.append('file', panicPhoto, 'file');
        file_data.append('file_type', panicPhoto.type);
        axios.post(this.$store.getters.url + '/api/cases/files/', file_data)
            .then(() => {
              note.value = '';
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'File Uploaded'
              })
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'File FAILED: ' + error
              })
            }).finally(() => {
                getFiles();
            });
      });
      emit('reload');
    }

    let media = ref([]);
    function getFiles(){
      axios.get(this.$store.getters.url+'/api/cases/files?task_id='+props.returned_tasks.id)
          .then((res) => {
            media.value = res.data.data;
          })
          .catch(() => {
          }).finally(() => {
      });
    }

    onMounted(() => {
      getFiles();
    });

    const {getRootProps, getInputProps, ...rest} = useDropzone({onDrop});
    return {
      note,
      media,
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
  methods:{
    hasImageExtension(path){
      return path.match(/\.(jpeg|jpg|gif|png)$/) != null;
    },
    hasVideoExtension(path){
      return path.match(/\.(mp4|webm|ogg)$/) != null;
    },
  }
}
</script>

<style>
.camera-container {
  z-index: 10; /* Higher than the footer */
}
video {
  /* This will make the video fill the element's dimensions, possibly cropping it */
  object-fit: cover;

  /* Use the entire viewport width and height */
  width: 100vw;
  height: 100vh;

  /* Optional: Center the video in its container */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
