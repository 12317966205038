<template>
  <q-list bordered>
      <q-item>
        <q-item-section class="text-h5">Case: {{this.returned_cases.id}}</q-item-section>
        <q-item-section>
            People of interest
        </q-item-section>
          <q-item-section avatar>
            <a >
              <q-icon color="primary" name="settings"></q-icon>
            </a>
          </q-item-section>
      </q-item>

      <q-item>
            <q-btn round icon="link" @click="linkPerson()"></q-btn>
            <q-btn round icon="add" @click="addPerson()"></q-btn>
      </q-item>
      <q-item>
        <q-item-section>
          <q-item-label>
            People of interest
          </q-item-label>
        </q-item-section>
      </q-item>
    <q-item v-for="(person,index) in returned_cases.pois" :key="index">
      <q-list>
        <q-item>
          <q-item-section thumbnail>
            <q-item-label bordered>
              <p>Fullname: {{ person.first_name }} {{ person.last_name }}</p>
              <p>Type:
                  {{ person.poi_type.description }}
              </p>
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
              <q-btn round icon="delete" @click="removePerson(person.id)"></q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </q-item>
  </q-list>

  <q-dialog v-model="this.personLinkModalVisible" transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 80%">
      <q-card-section class="q-pa-md">
        <q-form>
          <label class="text-h5">
            Select People
          </label>
          <q-select
              ref="poi_select"
              v-model="this.selected_person"
              :options="this.people"
              use-input
              @filter="filterFn"
              use-chips
              multiple
              clearable
              :loading="this.loading"
              label="Search and Select"
              :option-label="option => option.first_name + ' ' + option.last_name"
          >
          </q-select>
        </q-form>
      </q-card-section>
      <q-card-actions class="q-pa-md">
        <q-btn submit @click="closeModal()" label="Cancel" color="secondary"></q-btn>
        <q-btn ref="submitPerson" submit @click="submitPerson()" label="Submit" color="primary"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="this.personAddModalVisible" transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pa-md">
        <q-form>
              <label>Person Name (*)</label>
              <q-input
                  v-model="name"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
              ></q-input>
              <label>Person Surname (*)</label>
              <q-input
                  v-model="surname"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
              ></q-input>
              <label>Person Type</label>
              <q-select
                  v-model="id_type"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
                  :option-value="option => option.value"
                  :options="poi_types"
              >
              </q-select>
              <label>Person ID Nr</label>
              <q-input v-model="id_number"></q-input>
              <label>Cellphone</label>
              <q-input v-model="cellphone"></q-input>
              <label>Race</label>
              <q-select
                  v-model="race"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
                  :option-value="option => option.value"
                  :options="this.races"
              >
              </q-select>
              <label>Gender</label>
              <q-select
                  v-model="gender"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
                  :option-value="option => option.value"
                  :options="this.genders"
              >
              </q-select>


        </q-form>
      </q-card-section>
      <q-card-actions class="q-pa-md">
        <q-btn submit @click="closeAddModal()" label="Cancel" color="secondary"></q-btn>
        <q-btn submit @click="createPerson()" label="Submit" color="primary"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script>

import axios from "axios";
import {Notify} from "quasar";
export default {
  name: 'CasePoi',
  props: [
      'returned_cases'
  ],
  data(){
      return {
          loading : false,
          name : null,
          surname : null,
          id_number : null,
          id_type: null,
          poi_types : [],
          genders : [],
          races : [],
          cellphone : null,
          gender : null,
          race : null,
          selected_person : null,
          people_involved : [],
          people : [],
          options : [],
          personLinkModalVisible : false,
          personAddModalVisible : false,
      }
  },
  mounted(){
    this.getPersonTypes();
    this.getGenders();
    this.getRaces();
    this.getPeople();
  },
  computed:{
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  methods:{
    onEnterPress() {
        this.$refs.selectRef.blur();
        this.$refs.submitPerson.$el.focus();
    },
    submitPerson(){
      axios.post(this.$store.getters.url+'/api/api-docket-poi-link',{
        docket_id : this.returned_cases.id,
        poi_id : this.selected_person,
        user_id : this.user_id
      }).then(() => {
        Notify.create({
          message: 'People added successfully',
          color: 'positive',
          textColor: 'white',
          timeout: 2000,
          position: 'center'
        });
        this.selected_person = null;
        this.personLinkModalVisible = false;
        this.$emit('reload');
      })
    },
    createPerson(){
      console.log(this.id_type);
      axios.post(this.$store.getters.url+'/api/api-pois',{
        docket_id : this.returned_cases.id,
        first_name : this.name,
        poi_type_id : this.id_type.value,
        last_name : this.surname,
        id_number : this.id_number,
        cellphone : this.cellphone,
        user_id : this.user_id,
        gender: this.gender.value
      }).then(() => {
        Notify.create({
          message: 'People added successfully',
          color: 'positive',
          textColor: 'white',
          timeout: 2000,
          position: 'center'
        });
        this.selected_person = null;
        this.$emit('reload');
        this.closeAddModal();
      })
    },
    removePerson(person){
      axios.post(this.$store.getters.url+'/api/api-docket-poi-unlink',{
        poi_id : person,
        docket_id : this.returned_cases.id,
      },{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+this.$store.getters.token,
        }
      }).then(() => {
        Notify.create({
          message: 'People removed successfully',
          color: 'positive',
          textColor: 'white',
          timeout: 2000,
          position: 'center'
        });
        this.selected_person = null;
        this.$emit('reload');
        this.personLinkModalVisible = false;
      })
    },
    getGenders(){
      axios.get(this.$store.getters.url+'/api/api-poi-gender')
          .then((res) => {
            res.data.data.forEach((type) => {
              this.genders.push({label: type.description, value: type.id});
            });
          });
    },
    getRaces(){
      axios.get(this.$store.getters.url+'/api/api-poi-race')
          .then((res) => {
            res.data.data.forEach((type) => {
              this.races.push({label: type.description, value: type.id});
            });
          });
    },
    getPersonTypes(){
      axios.get(this.$store.getters.url+'/api/api-poi-types')
          .then((res) => {
            res.data.forEach((type) => {
              this.poi_types.push({label: type.description, value: type.id});
            });
          })
    },
    abortFilterFn () {
    },
    async filterFn(val, update, abort) {
      if (val === '') {
        this.people = [];
        return;
      }
      this.loading = true;
      try {
        // Replace with your actual API endpoint
        this.getPeople(val);
        update();
      } catch (error) {
        console.error(error);
        abort();
      } finally {
        this.loading = false;
      }
    },
    getPeople(val){
      this.loading = true;
        axios.get(this.$store.getters.url+'/api/api-pois',{
          params: {
            search_term:val
          }
        })
        .then((res) => {
          this.people = res.data;
          this.loading = false;
        })
        .catch(() => {
        }).finally(() => {
        });
    },
    linkPerson(){
          this.personLinkModalVisible = true;
    },
    addPerson(){
          this.personAddModalVisible = true;
    },
    closeAddModal(){
        this.gender = null;
        this.name = null;
        this.surname = null;
        this.cellphone = null;
        this.id_number = null;
        this.personAddModalVisible = false;
    },
    closeModal(){
      this.personLinkModalVisible = false;
    }
  }
}
</script>
