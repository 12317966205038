<template>
  <div class="hello">
    <q-card class="my-card">
      <q-card-section>
        <q-item-label class="text-h3">
          Welcome to your messaging Inbox
        </q-item-label>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-list bordered>
          <div v-for="(conversation,index) in messages" :key="index" class="q-my-sm">
            <q-item v-if="conversation.message_type === 'private_message'" :href="'/private-message/'+conversation.user_id"  clickable v-ripple>
            <q-item-section  avatar>
              <q-avatar color="primary" text-color="white">
                {{ this.getFirstLetter(conversation.user_name) }} {{ this.getFirstLetter(conversation.user_surname) }}
              </q-avatar>
            </q-item-section>
              <q-item-section>
                <q-item-label>{{ conversation.user_name }} {{ conversation.user_surname }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <span>{{ conversation.unread_count }}</span>
                <q-icon v-if="(parseInt(conversation.unread_count))===0" name="chat_bubble" color="gray"></q-icon>
                <q-icon v-if="(parseInt(conversation.unread_count))>0" name="chat_bubble" color="green"></q-icon>
              </q-item-section>
          </q-item>
            <q-item v-if="conversation.message_type === 'group_message'" :href="'/group-message/'+conversation.group_id" clickable v-ripple>
              <q-item-section avatar>
                <q-avatar color="primary" text-color="white">
                  {{ conversation.group_name }}
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ conversation.group_name }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <span>{{ conversation.unread_count }}</span>
                <q-icon v-if="(parseInt(conversation.unread_count))===0" name="chat_bubble" color="gray"></q-icon>
                <q-icon v-if="(parseInt(conversation.unread_count))>0" name="chat_bubble" color="green"></q-icon>
              </q-item-section>
            </q-item>
            <q-item v-if="conversation.message_type === 'docket_message'" :href="'/docket-message/'+conversation.docket_id" clickable v-ripple>
              <q-item-section avatar>
                <q-avatar color="primary" text-color="white">
                  {{ conversation.docket.reference }}
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ conversation.docket.reference }} {{ conversation.docket.description }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <span>{{ conversation.unread_count }}</span>
                <q-icon v-if="(parseInt(conversation.unread_count))===0" name="chat_bubble" color="gray"></q-icon>
                <q-icon v-if="(parseInt(conversation.unread_count))>0" name="chat_bubble" color="green"></q-icon>
              </q-item-section>
            </q-item>
          </div>
        </q-list>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import axios from "axios";
import { Notify } from 'quasar'
import NoSleep from 'nosleep.js';
import {getToken, onMessage} from "firebase/messaging";
import {messaging} from "@/firebase";

export default {
  name: 'PageInbox',
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
  },
  computed:{
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  data(){
    return {
      messages : Object,
      draw_list : 0,
    }
  },
  mounted(){
      this.setupPage();
      this.initializeFirebase();
      this.getMessages();
  },
  methods: {
    getFirstLetter(string){
        return string.charAt(0);
    },
    setupPage(){
      const router = useRouter();
      let token = this.$store.getters.token;
      if( !token ){
        router.push({ name: "login"})
      }else{
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
    },
    registerToken(token) {
      let axios_token = this.$store.getters.token;
      if( axios_token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + axios_token;
      }
      axios.post(
          this.$store.getters.url+"/api/api-web-notification-token",
          {
            token: token
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json"
            }
          }
      ).then(() => {
      })
    },
    initializeFirebase() {
      getToken(messaging, { vapidKey: process.env.VUE_APP_vapid_Key })
          .then((token) => {
            console.log("Token is "+token);
            this.registerToken(token)
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      onMessage(messaging, (payload) => {
        console.log("Message received");
        console.log(payload);
        this.playSound();
        this.getMessages();
      });
    },
    playSound () {
      let data = { soundurl : 'https://api.blackwidow.org.za/sos.mp3'}
      let audio = new Audio(data.soundurl);
      audio.play();
    },
    getMessages() {
      axios.get(this.$store.getters.url+'/api/api-inbox-messages')
          .then((res) => {
            this.messages = res.data;
            Notify.create({
              message: 'Messages loaded',
              color: 'green',
              textColor: 'white',
              icon: 'cloud_done'
            })
            console.log(this.messages);
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    deleteMessage(index) {
      let url = this.$store.getters.url+'/api/cases/messages/' + index;
      axios.delete(url)
          .then(() => {
            this.getMessages();
          })
          .catch(() => {
          }).finally(() => {
              this.getMessages();
          });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.icon-with-number {
  position: relative;
  display: inline-block;
  font-size: 24px;
}

.icon-with-number .number {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
</style>

