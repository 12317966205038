<template>
  <router-view/>
</template>
<script>
import {messaging} from "@/firebase";
import { getToken,onMessage } from "firebase/messaging";
import axios from "axios";
import NoSleep from "nosleep.js";
import {Notify} from "quasar";

export default {
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
  },
  computed:{
    user_id(){
      return this.$store.getters.user_id;
    },
    latitude(){
      return this.$store.getters.latitude;
    },
    longitude(){
      return this.$store.getters.longitude;
    }
  },
  mounted(){
    this.enableNotifications();
    this.checkAndGetLocation();
  },
  methods: {
    setupPage(){
    },
    checkAndGetLocation() {
      // Use navigator.permissions.query to check for geolocation permissions
      navigator.permissions.query({ name: 'geolocation' })
          .then((result) => {
            if (result.state === 'granted') {
              // Permissions are granted, get current position
              this.getCurrentPosition();
            } else if (result.state === 'prompt') {
              // Permissions are prompted, request permission and then get position
              navigator.geolocation.getCurrentPosition(
                  this.handlePositionSuccess,
                  this.handlePositionError,
                  { enableHighAccuracy: true }
              );
            } else if (result.state === 'denied') {
              alert('Location Not allowed');
              // Optionally, request permissions or show further instructions
            }
          })
          .catch((error) => {
            console.error('Error checking geolocation permissions:', error);
          });
    },

    getCurrentPosition() {
      // Get the current position using the Geolocation API
      navigator.geolocation.getCurrentPosition(
          this.handlePositionSuccess,
          this.handlePositionError,
          { enableHighAccuracy: true }
      );
    },

    handlePositionSuccess(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      localStorage.setItem('latitude', JSON.stringify(latitude));
      localStorage.setItem('longitude', JSON.stringify(longitude));

      this.$store.commit('UPDATE_LAT', latitude);
      this.$store.commit('UPDATE_LNG', longitude);

      console.log('Location updated successfully:', latitude, longitude);
    },

    handlePositionError(error) {
      console.error('Error getting location:', error);
      alert('Unable to retrieve location.');
    },
    registerToken(token) {
      let axios_token = this.$store.getters.token;
      if( axios_token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + axios_token;
      }
      axios.post(
          this.$store.getters.url+"/api/api-web-notification-token",
          {
            token: token
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json"
            }
          }
      ).then(() => {
      })
    },
    initializeFirebase() {
      getToken(messaging, {vapidKey: process.env.VUE_APP_vapid_Key})
          .then((token) => {
            console.log("Token is " + token);
            this.registerToken(token)
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      onMessage(messaging, (payload) => {
        Notify.create({
          color: 'green-5',
          textColor: 'white',
          icon: 'check',
          message:   payload.notification.title+','+payload.notification.body,
          timeout: 10000,
        })
        this.playSound();
        this.userInput = payload.notification.title + ',' + payload.notification.body;
        setTimeout(() => this.speakMessage(), 3500);
      });
    },
    enableNotifications() {
      if (!("Notification" in window)) {
        alert("Notifications are not supported");
      } else if (Notification.permission === "granted") {
        if(this.user_id) {
          this.initializeFirebase();
        }
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            if(this.user_id){
              this.initializeFirebase();
            }
          }
        })
      } else {
        alert("No permission to send notification")
      }
      this.requestPermission = Notification.permission;
    },


    playSound () {
      var data = { soundurl : '/sos.mp3'}
      var audio = new Audio(data.soundurl);
      audio.play();
    },
    speakMessage() {
      if (this.synth.speaking) {
        console.error('speechSynthesis.speaking');
        return;
      }
      if (this.userInput !== '') {
        this.validation = false;
        let sInstance = new SpeechSynthesisUtterance(this.userInput);
        // eslint-disable-next-line no-unused-vars
        sInstance.onend = function (event) {
          console.log('SpeechSynthesisUtterance.onend');
        }
        // eslint-disable-next-line no-unused-vars
        sInstance.onerror = function (event) {
          console.error(event);
        }
        sInstance.pitch = this.pitch;
        sInstance.rate = this.rate;
        this.synth.speak(sInstance);
      } else {
        this.validation = true;
      }
      this.userInput = '';
    },


  },
  data(){
    return {
      firebaseStarted : "false",
      userInput: '',
      pitch: 1,
      rate: 1,
      synth: window.speechSynthesis,
      validation: false
    }
  }
};
</script>