<template>
  <q-dialog persistent :full-width="true" v-model="modal_shown">
    <q-page-container>
      <q-page class="q-pa-md">
        <q-card class="dialog-card my-card bg-gray text-gray">
          <q-card-section>
            <q-item v-ripple>
              <q-item-section class="text-h5">{{this.task.reference}} </q-item-section>
              <q-item-section avatar>
                <q-icon color="primary" name="settings">

                </q-icon>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <p class="text-h6">{{ the_form.type }} : {{ the_form.description}}</p>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-btn @click="this.currentPage = 1" :class="currentPage === 1 ? 'bg-blue text-white' : 'bg-grey text-black'">Not Filled</q-btn>
                <q-btn @click="this.currentPage = 2" :class="currentPage === 2 ? 'bg-blue text-white' : 'bg-grey text-black'">Submitted</q-btn>
              </q-item-section>
            </q-item>

            <div v-if="currentPage === 1">
              <div v-for="form_question in the_form.form_questions" :key="form_question.id">
                <div v-for="item in form_question.form_answers" :key="item.id">
                  <div v-if="item.submitted === 0">
                    <q-item>
                      <q-item-section>
                        <q-item-label class="q-mt-md q-mb-md">
                          {{ form_question.description }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section>
                        <q-item-label class="q-mt-md q-mb-md">
                          {{ form_question.label }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section>
                        <div v-if="form_question.type==='Datetime'" class="flex py-4 px-4">
                          <input  v-model="item.answers" v-if="JSON.parse(form_question.options).timestampType==='date'" type="date"/>
                          <input  v-model="item.answers" v-if="JSON.parse(form_question.options).timestampType==='datetime'" type="datetime-local"/>
                          <input  v-model="item.answers" v-if="JSON.parse(form_question.options).timestampType==='time'" type="time"/>
                        </div>
                        <div v-if="form_question.type==='Text'" class="flex  py-4 px-4">
                                        <textarea
                                            v-model="item.answers"
                                            :rows="JSON.parse(form_question.options).lines"
                                            :minlength="JSON.parse(form_question.options).minLength"
                                            :maxlength="JSON.parse(form_question.options).maxLength"
                                        />
                        </div>
                        <div v-if="form_question.type==='Signature'" class="flex py-4 px-4">
                          <input  type="hidden" v-model="item.answers"/>
                          <SignatureCard :model-value="item.file"
                                         @update:extension="extension => handleExtension(extension, form_question)"
                                         @update:files="files => handleFiles(files, form_question)">
                          </SignatureCard>
                        </div>
                        <div v-if="form_question.type==='Number'" class="flex  py-4 px-4">
                          <input
                              v-model="item.answers" type="number"
                              :min="JSON.parse(form_question.options).Minimum"
                              :max="JSON.parse(form_question.options).Maximum"
                          />
                        </div>
                        <div v-if="form_question.type==='Choices'" class="flex  py-4 px-4">
                          <select   v-model="item.answers" :multiple="JSON.parse(form_question.options).multiChoice" class="rounded-lg mt-2 mb-2">
                            <option selected>
                              -- Select --
                            </option>
                            <option v-for="choice in JSON.parse(form_question.options).selectable_options" :key="choice" :value="choice">
                              {{ choice }}
                            </option>
                          </select>
                        </div>
                        <div v-if="form_question.type==='Currency'" class="flex flex-col py-4 px-4">
                          <span class="currency-symbol">
                            <strong>
                                {{this.getCurrency(JSON.parse(form_question.options).selectedCurrency)}}
                            </strong>
                           </span>
                          <input
                              v-model="item.answers" type="number"
                              :min="JSON.parse(form_question.options).Minimum"
                              :max="JSON.parse(form_question.options).Maximum"
                          />
                        </div>
                        <div v-if="form_question.type==='Boolean'" class="flex py-4 px-4">
                          <select  v-model="item.answers" class="rounded-lg mt-2 mb-2">
                            <option selected>
                              -- Select --
                            </option>
                            <option :value="JSON.parse(form_question.options).falseOption">
                              {{ JSON.parse(form_question.options).falseOption }}
                            </option>
                            <option :value="JSON.parse(form_question.options).trueOption">
                              {{ JSON.parse(form_question.options).trueOption }}
                            </option>
                            <option :value="'N/A'">
                              N/A
                            </option>
                          </select>
                        </div>
                        <div class="q-mt-md q-mb-md" v-if="form_question.type==='Boolean' && JSON.parse(form_question.options).negativeActionValues.hasPhotos">
                          <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                            <DropzoneComponent
                                @update:extension="extension => handleExtension(extension, form_question)"
                                @update:files="files => handleFiles(files, form_question)"
                                :type="(form_question.options).fileType">
                            </DropzoneComponent>
                          </div>
                          <div class="w-1/3" v-if="form_question.file">
                            <img
                                :src="createBlob(form_question)"
                                style="height: 140px; max-width: 150px"
                            >
                          </div>
                        </div>
                        <div v-if="form_question.type==='File'" class="flex  py-4 px-4">
                          <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                            <DropzoneComponent
                                @update:extension="extension => handleExtension(extension, form_question)"
                                @update:files="files => handleFiles(files, form_question)"
                                :type="(form_question.options).fileType">
                            </DropzoneComponent>
                          </div>
                          <div class="w-1/3" v-if="form_question.file">
                            <img
                                :src="createBlob(form_question)"
                                style="height: 140px; max-width: 150px"
                            >
                          </div>
                        </div>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-btn @click="storeAnswer(form_question)">Submit</q-btn>
                    </q-item>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="currentPage === 2">
              <div v-for="form_question in the_form.form_questions" :key="form_question.id">
                <div v-for="item in form_question.form_answers" :key="item.id">
                  <div v-if="item.submitted === 1">
                    <q-item>
                      <q-item-section>
                        <q-item-label class="q-mt-md q-mb-md">
                          {{ form_question.label }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section>
                        <div v-if="form_question.type==='Datetime'" class="flex py-4 px-4">
                          <input  v-model="item.answers" v-if="JSON.parse(form_question.options).timestampType==='date'" type="date"/>
                          <input  v-model="item.answers" v-if="JSON.parse(form_question.options).timestampType==='datetime'" type="datetime-local"/>
                          <input  v-model="item.answers" v-if="JSON.parse(form_question.options).timestampType==='time'" type="time"/>
                        </div>
                        <div v-if="form_question.type==='Text'" class="flex  py-4 px-4">
                                        <textarea
                                            v-model="item.answers"
                                            :rows="JSON.parse(form_question.options).lines"
                                            :minlength="JSON.parse(form_question.options).minLength"
                                            :maxlength="JSON.parse(form_question.options).maxLength"
                                        />
                        </div>
                        <div v-if="form_question.type==='Signature'" class="flex py-4 px-4">
                          <input  type="hidden" v-model="item.answers"/>
                          <SignatureCard :model-value="item.file"
                                         @update:extension="extension => handleExtension(extension, form_question)"
                                         @update:files="files => handleFiles(files, form_question)">
                          </SignatureCard>
                        </div>
                        <div v-if="form_question.type==='Number'" class="flex  py-4 px-4">
                          <input
                              v-model="item.answers" type="number"
                              :min="JSON.parse(form_question.options).Minimum"
                              :max="JSON.parse(form_question.options).Maximum"
                          />
                        </div>
                        <div v-if="form_question.type==='Choices'" class="flex  py-4 px-4">
                          <select   v-model="item.answers" :multiple="JSON.parse(form_question.options).multiChoice" class="rounded-lg mt-2 mb-2">
                            <option selected>
                              -- Select --
                            </option>
                            <option v-for="choice in JSON.parse(form_question.options).selectable_options" :key="choice" :value="choice">
                              {{ choice }}
                            </option>
                          </select>
                        </div>
                        <div v-if="form_question.type==='Currency'" class="flex flex-col  py-4 px-4">
                          <span class="currency-symbol">
                            <strong>
                                {{this.getCurrency(JSON.parse(form_question.options).selectedCurrency)}}
                            </strong>
                           </span>
                          <input
                              v-model="item.answers" type="number"
                              :min="JSON.parse(form_question.options).Minimum"
                              :max="JSON.parse(form_question.options).Maximum"
                          />
                        </div>
                        <div v-if="form_question.type==='Boolean'" class="flex py-4 px-4">
                          <select  v-model="item.answers" class="rounded-lg mt-2 mb-2">
                            <option selected>
                              -- Select --
                            </option>
                            <option :value="JSON.parse(form_question.options).falseOption">
                              {{ JSON.parse(form_question.options).falseOption }}
                            </option>
                            <option :value="JSON.parse(form_question.options).trueOption">
                              {{ JSON.parse(form_question.options).trueOption }}
                            </option>
                            <option :value="'N/A'">
                              N/A
                            </option>
                          </select>
                        </div>
                        <div class="q-mt-md q-mb-md" v-if="form_question.type==='Boolean' && JSON.parse(form_question.options).negativeActionValues.hasPhotos">
                          <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                            <DropzoneComponent
                                @update:extension="extension => handleExtension(extension, form_question)"
                                @update:files="files => handleFiles(files, form_question)"
                                :type="(form_question.options).fileType">
                            </DropzoneComponent>
                          </div>
                          <div class="w-1/3" v-if="form_question.file">
                            <img
                                :src="createBlob(form_question)"
                                style="height: 140px; max-width: 150px"
                            >
                          </div>
                        </div>
                        <div v-if="form_question.type==='File'" class="flex  py-4 px-4">
                          <div class="ml-10 block border-2 rounded-lg bg-white py-4 px-4">
                            <DropzoneComponent
                                @update:extension="extension => handleExtension(extension, form_question)"
                                @update:files="files => handleFiles(files, form_question)"
                                :type="(form_question.options).fileType">
                            </DropzoneComponent>
                          </div>
                          <div class="w-1/3" v-if="form_question.file">
                            <img
                                :src="createBlob(form_question)"
                                style="height: 140px; max-width: 150px"
                            >
                          </div>
                        </div>
                      </q-item-section>
                    </q-item>
                    <q-item>
                      <q-btn @click="storeAnswer(form_question)">Submit</q-btn>
                    </q-item>
                  </div>
                </div>
              </div>
            </div>
            <q-item class="mt-4 mb-4">
              <q-btn @click="this.$emit('closeModal')">Close</q-btn>
            </q-item>
          </q-card-section>
        </q-card>
      </q-page>
    </q-page-container>
  </q-dialog>
</template>

<script>
import SignatureCard from "@/components/SignatureCard";
import axios  from "axios";
import {Notify} from "quasar";
import DropzoneComponent from "@/components/DropzoneComponent.vue";
export default {
  name: "ChecklistPage",
  props: [ 'form','showModal','task'],
  emits: ['closeModal','reloadForm'],
  components: {
    DropzoneComponent,
    SignatureCard
  },
  computed:{
    url(){
      return this.$store.getters.url;
    }
  },
  watch:{
    the_form:{
      handler(){
        this.storeFormWithoutClosing();
      },
      deep: true
    }
  },
  methods:{
    getCurrency(currency_id){
      let selectedCurrency = this.currencies.find(currency => parseInt(currency.id) === parseInt(currency_id));
      if(selectedCurrency !== undefined){
        return selectedCurrency.symbol;
      }
    },
     getCurrencies() {
      try {
        axios.get(this.url + '/api/api-currency')
            .then((res) => {
              this.currencies = res.data;
            });
      } catch (e) {
        console.log(e);
      }
    },
    createBlob(item){
      return URL.createObjectURL(item.file);
    },
    handleFiles(files,item){
      item.file = files;
      console.log("Files: ");
      console.log(item);
      if(item.type==='File' || item.type==='Signature'){
        item.form_answers[0].answers = JSON.parse(item.options).fileName;
      }
      console.log("Files: ");
    },
    handleExtension(extension,item){
      console.log("Extension: ", extension)
      item.file_extension = extension;
    },
    storeForm(){
      let formData = new FormData();
      this.the_form.form_questions.forEach((item, index) => {
        if (item.file) {
          let blob = new Blob([item.file], { type: 'image/png' });
          let file_name = JSON.parse(item.options).fileName + '.' + item.file_extension;
          if(item.type==='Boolean'){
            file_name = item.description+'.' + item.file_extension;
          }
          formData.append(`form_questions[${index}][id]`, item.id);
          formData.append(`form_questions[${index}][file]`,blob , file_name);
          formData.append(`form_questions[${index}][form_answers]` , JSON.stringify(item.form_answers));
        }else {
          formData.append(`form_questions[${index}][id]`, item.id);
          formData.append(`form_questions[${index}][form_answers]`, JSON.stringify(item.form_answers));
        }
      });
      let config = {
        headers:{
          'Authorization': 'Bearer ' + this.$store.getters.token,
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(this.url+'/api/api-form-filled-store',formData,config)
          .then((res) => {
            if(res.data.type==='success'){
              this.$emit('closeModal');
              Notify.create({
                message: res.data.message,
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done'
              });
            }else{
              Notify.create({
                message: res.data.message,
                color: 'red-4',
                textColor: 'white',
                icon: 'warning'
              });
            }
          }).catch((e) => {
        console.log(e);
      });
    },
    storeAnswer(item){
      let formData = new FormData();
      if (item.file) {
        let blob = new Blob([item.file], { type: 'image/png' });
        let file_name = JSON.parse(item.options).fileName + '.' + item.file_extension;
        if(item.type==='Boolean'){
          file_name = item.description+'.' + item.file_extension;
        }
        formData.append(`form_question_id`, item.id);
        formData.append(`form_answer_id`, item.form_answers[0].id);
        formData.append(`form_question_file`,blob , file_name);
        formData.append(`form_answers` , JSON.stringify(item.form_answers[0].answers));
      }else {
        formData.append(`form_answer_id`, item.form_answers[0].id);
        formData.append(`form_question_id`, item.id);
        formData.append(`form_answers`, JSON.stringify(item.form_answers[0].answers));
      }
      let config = {
        headers:{
          'Authorization': 'Bearer ' + this.$store.getters.token,
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(this.url+'/api/api-form-answer-store',formData,config)
          .then((res) => {
            if(res.data.type==='success'){
              item.form_answers[0].submitted =1;
              Notify.create({
                message: res.data.message,
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done'
              });
              this.$emit('reloadForm');
            }else{
              Notify.create({
                message: res.data.message,
                color: 'red-4',
                textColor: 'white',
                icon: 'warning'
              });
            }
          }).catch((e) => {
        console.log(e);
      });
    },
    storeFormWithoutClosing() {
    }
  },
  data(){
    return {
      currentPage : 1,
      currencies: [],
      the_form : this.form,
      modal_shown: this.showModal
    }
  },
  mounted() {
    console.log(this.the_form);
    this.the_form.form_questions.sort((a, b) => a.order - b.order);
    this.getCurrencies();
  }
}
</script>

<style scoped>
.currency-symbol {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
input {
  padding-left: 20px; /* Adjust padding to make space for the symbol */
}
</style>