import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';
import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'
import { defineCustomElements } from '@ionic/pwa-elements/loader'; // <== NEW
import Vue3Signature from "vue3-signature"
import './registerServiceWorker'
import Vuex from 'vuex';
import store from '@/stores/appStore.js';
import './firebase.js'; // Import firebase.js to initialize Firebase

store.dispatch('initializeStore');
store.dispatch('updateUnreadMessageCounter');

createApp(App)
    .use(Quasar, {
        plugins: {
            Notify
        },
        config: {
            notify: { /* look at QuasarConfOptions from the API card */ }
        }
    })
    .use(Vuex)
    .use(store)
    .use(Vue3Signature)
    .use(quasarUserOptions)
    .use(router)
    .mount('#app')

defineCustomElements(window);  // <== NEW
